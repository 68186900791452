import { default as StudioSection } from '../components/StudioSection';

const Studio = () => {
  return (
      <div>
        <StudioSection/>
      </div>
  );
}
  
export default Studio;