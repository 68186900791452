import React, { Component } from 'react';
import './ContactSection.css';

class ContactSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clientName: '',
            clientPhone: '',
            clientEmail: '',
            projectDescription: '',
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value 
        })
    }

    handleSubmit = e => {
        e.preventDefault();

        if( window.confirm('입력하신 내용으로 문의하시겠습니까?') ) {
            this.sendMessage();
        }
    }

    sendMessage() {
        const url = "https://1yvrwgn48k.execute-api.ap-northeast-2.amazonaws.com/dev/email/send";
        const option = {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                clientName: this.state.clientName,
                clientPhone: this.state.clientPhone,
                clientEmail: this.state.clientEmail,
                projectDescription: this.state.projectDescription
            })
        };
        
        fetch(url, option).then(response => {
            alert("문의주셔서 감사합니다. 빠른 시일 내에 연락드리겠습니다.");
        })
    }

    render() {
        return (
            <section id="contact">
                <div className="container">
                    <div className="row">
    
                        <div className="col-md-offset-1 col-md-10 col-sm-12">
                                <div className="section-title">
                                    <h3 className="contact-title">CONTACT</h3>
                                    <br/><br/>
                                    <p>윈터버드의 브랜드 관련한  협업, 협찬, 라이선싱에 관련하여 궁금하신 내용은 사업자 정보와 함께 이메일을 통해 문의주세요</p>
                                    <p>nizmiz0121@gmail.com</p>
                                    <br/><br/>
                                    <p>윈터버드는 다양한 브랜드, 디자이너, 기업과 협업을 진행합니다. 프로젝트 문의는 아래 이메일을 통해 문의주세요</p>
                                    <p>winterbird0121@gmail.com</p>
                                </div>
    
                                <form onSubmit={this.handleSubmit}>
                                    <div className="col-md-6 col-sm-6">
                                        <input value={this.state.clientName} onChange={this.handleChange} name="clientName" type="text" className="form-control" placeholder="Name"/>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <input value={this.state.clientPhone} onChange={this.handleChange} name="clientPhone" type="telephone" className="form-control" placeholder="Phone Number"/>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <input value={this.state.clientEmail} onChange={this.handleChange} name="clientEmail" type="email" className="form-control" placeholder="Email"/>
                                    </div>
                                    <div className="col-md-12 col-sm-12"> 
                                        <textarea value={this.state.projectDescription} onChange={this.handleChange} name="projectDescription" className="form-control" rows="5" placeholder="Project Details"></textarea>
                                    </div>
                                    <div className="col-md-3 col-sm-4">
                                        <input type="submit" className="form-control" value="Send Message"/>
                                    </div>
                                </form>
                        </div>
    
                    </div>
                </div>
            </section>
        );
    }
    
}

export default ContactSection;
