const HomeSection = () => {
    return (
        <section id="home">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h3>윈터버드는 트렌디한 감성을 담은 다양한 콘텐츠를 선보여<br/><br/>일상에 작은 재미를 주고자 합니다.</h3>
              </div>
            </div>
          </div>
        </section>
    );
}

export default HomeSection;