import { default as ContactSection } from '../components/ContactSection';

const Blog = () => {
  return (
      <div>
        <ContactSection/>
      </div>
  );
}
  
export default Blog;