const StudioSection = () => {
    return (
        <section id="about">
     <div class="container">
          <div class="row">

               <div class="col-md-offset-1 col-md-10 col-sm-12">
                    <div class="section-title">
                         <h3>about our story</h3>
                         <p>윈터버드는 재미와 간편한 콘텐츠를 제작하는 콘텐츠 스튜디오 입니다.</p>
                    </div>

                    <div class="col-md-8 col-sm-12">
                         <img src="images/about-image.jpg" class="img-responsive" alt="About"/>
                    </div>

                    <div class="col-md-4 col-sm-12">
                         <h2><strong>winterbird<br/>contents studio</strong></h2>
                         <ul>
                              <li>익숙하지만 우리의 아이디어를 담은 새로운 가치를 창출합니다.</li>
                              <li>캐릭터, 애니메이션, 이모티콘 등 다양한 콘텐츠 장르를 도전합니다</li>
                              <li> 공감과 재미를 통해 긍정적이고 좋은 영향력을 가진 콘텐츠를 만듭니다.</li>
                         </ul>
                    </div>

                    <div class="clearfix"></div>

                     <hr/>

                    <p style={{"text-align": "center"}}>우리가 즐길 수 있고 긍정적인 영향을 줄수있는 프로젝트를 진행합니다.</p>
                    <p style={{"text-align": "center"}}>우리는 프로젝트마다 그 프로젝트에 가장 맞는 팀원들과 함께 빠르고 우수한 퀄리티의 콘텐츠를 만듭니다. </p>

                    <hr/>

                    <div class="row">
                         <div class="col-md-4 col-sm-6">
                              <h3>character</h3>
                              <ul>
                                   <li>기획</li>
                                   <li>디자인</li>
                                   <li>개발</li>
                                   <li>리뉴얼</li>
                              </ul>
                         </div>

                         <div class="col-md-4 col-sm-6">
                              <h3>contents</h3>
                              <ul>
                                   <li>이모티콘</li>
                                   <li>애니메이션</li>
                                   <li>굿즈상품개발</li>
                                   <li>OSMU</li>
                              </ul>
                         </div>

                         <div class="col-md-4 col-sm-6">
                              <h3>ETC</h3>
                              <ul>
                                   <li>브랜드 개발</li>
                                   <li>라이선싱</li>
                                   <li>강의</li>
                              </ul>
                         </div>
                    </div>

                    <br/><br/><br/><br/>
                    <div class="row">
                         <center>
                         <table>
                              <tr>
                                   <th width="150px"></th>
                                   <th></th>
                              </tr>
                              <tr>
                                   <td><h3>2021</h3></td>
                                   <td>
                                        <ul style={{"list-style": "none"}}>
                                             <li>04 . 청년창업사관학교 11기 선정.</li>
                                             <li>05 . 한국콘텐츠진흥원 독립단편애니메이션 선정.</li>
                                             <li>05 . 아이디어 융합 팩토리 선정.</li>
                                             <li>05 . 충북 콘텐츠 코리아랩 킥! 스타트업 신규 콘텐츠 선정.</li>
                                             <li>07 . 서울일러스트레이션페어 vol.11</li>
                                             <li>09 . 청주 문화도시조성사업 청년 문화아이디어 [살아있다] 지원사업 선정.</li>
                                             <li>09 . 저작권 육성 지원 사업</li>
                                             <li>09 . IP X GOODS with 메가박스 콜라보레이션 MD 제작.</li>
                                             <li>09 . 광주 ace fair.</li>
                                             <li>10 . 부산일러스트레이션페어 vol.02</li>
                                             <li>10 . 우연의 일치 카페 팝업스토어</li>
                                             <li>11 . 2021 넥스트 콘텐츠 페어</li>
                                             <li>11 . 캐릭터 라이선싱 페어 2021</li>
                                             <li>12 . 서울일러스트레이션페어 vol.12</li>
                                        </ul>
                                   </td>
                              </tr>
                              <tr>
                                   <td><h3>2020</h3></td>
                                   <td>
                                        <ul style={{"list-style": "none"}}>
                                             <li>01 . 크라우드펀딩 텀블벅 ‘앙버터냥’ 모찌 실내화 100% 달성.</li>
                                             <li>02 . 서울 금손페스티벌.</li>
                                             <li>04 . 충북 콘텐츠 코리아랩 캐릭터 상품화 지원.</li>
                                             <li>04  . 충북 콘텐츠 코리아랩 킥! 스타트업 신규 콘텐츠 선정.</li>
                                             <li>07 . 크라우드펀딩 텀블벅 ‘냥하’ 그립톡 732% 달성.</li>
                                             <li>07 . 청주 동부창고 c 팝업스토어.</li>
                                             <li>09 . 크라우드펀딩 텀블벅 ‘굿즈 제작지침서’ 2934% 달성.</li>
                                             <li>10 . 청주 문화도시조성사업 청년 문화아이디어 [살아있다] 지원사업 선정.</li>
                                             <li>10 . 충북 콘텐츠 코리아랩 콘텐츠 지식재산권 출원 지원 선정.</li>
                                             <li>11. 광주 ace fair.</li>
                                             <li>11. 충북 콘텐츠 코리아랩 콘텐츠 비즈니스 마케팅 지원.</li>
                                             <li>11. 롯데월드 크림 팝업스토어.</li>
                                             <li>12. 아이러브캐릭터 X SICAF 캐릭터 공모전 ‘냥하’ 캐릭터 수상.</li>
                                             <li>12. 아이러브캐릭터 X SICAF 캐릭터 공모전 ‘앙버터냥’ 캐릭터 수상.</li>
                                             <li>12. 아이러브캐릭터 X SICAF 캐릭터 공모전 ‘베리냥’ 캐릭터 수상.</li>
                                        </ul>
                                   </td>
                              </tr>
                              <tr>
                                   <td><h3>2019</h3></td>
                                   <td>
                                        <ul style={{"list-style": "none"}}>
                                             <li>12.14 윈터버드 사업자개설</li>
                                             <li>06 . 대한민국 고양이 캐릭터 공모전  수상.</li>
                                             <li>09 . 크라우드펀딩 텀블벅 ‘앙버터냥’ 뱃지 180%달성.</li>
                                             <li>09 . 제3회 대한민국ucc 캐릭터 공모전 및 일러스트 경연대회 수상</li>
                                             <li>12 . 서울 일러스트레이션 페어 vol.9</li>
                                             <li>12 . 서울 디저트페어 [초코딸기전]</li>
                                             <li>12 . 성남 청년 아이디어 제안 대회 수상</li>
                                             <li>12 . 별썸 문고 페스티벌 vol. 1</li>
                                        </ul>
                                   </td>
                              </tr>
                         </table>
                         </center>
                         
                    </div>

               </div>

          </div>
     </div>
</section>
    );
}

export default StudioSection;