const Footer = () => {
    return (
        <footer>
            <div className="container">
                  <div className="row">

                      <div className="col-md-3 col-sm-3">
                            <img className="winterbird-logo" src="images/logo/winterbird_logo.png"/>
                      </div>

                      <div className="col-md-4 col-sm-4">
                            <p>1sunhwan-ro, Seowon-gu, Cheongju-si, Chungcheongbuk-do, Republic of Korea</p>
                      </div>

                      <div className="col-md-offset-1 col-md-4 col-sm-offset-1 col-sm-3">
                            <p><a href="mailto:winterbird0121@gmail.com">winterbird0121@gmail.com</a></p>
                            <p>(+82)01082428131</p>
                      </div>

                      <div className="clearfix col-md-12 col-sm-12">
                            <hr/>
                      </div>

                      <div className="col-md-6 col-sm-6">
                            <div className="footer-copyright">
                                <p>ⓒ 2021 winterbird | All Rights Reserved.</p>
                            </div>
                      </div>

                      <div className="col-md-6 col-sm-6">
                            <ul className="social-icon">
                                <li><a target="_blank" href="https://www.veryberrybap.com/" className="fa fa-facebook"></a></li>
                                <li><a target="_blank" href="https://twitter.com/winterbird1214" className="fa fa-twitter"></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/winterbird_1214/" className="fa fa-linkedin"></a></li>
                            </ul>
                      </div>
                      
                  </div>
            </div>
        </footer>
    );
}

export default Footer;