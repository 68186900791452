import { default as HomeSection } from '../components/HomeSection';
import { default as PortfolioSection } from '../components/PortfolioSection';

const Index = () => {
  return (
      <div>
        <HomeSection/>
        <PortfolioSection/>
      </div>
  );
}
  
export default Index;