import { Link } from "react-router-dom";

const Navigation = () => {
      return (
        <div className="navbar navbar-default navbar-static-top" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <button className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span className="icon icon-bar"></span>
                <span className="icon icon-bar"></span>
                <span className="icon icon-bar"></span>
              </button>
              <a href="/" className="navbar-brand"><img className="winterbird-logo" src="images/logo/winterbird_logo.png"/></a>
            </div>
            <div className="collapse navbar-collapse">
              <ul className="nav navbar-nav navbar-right">
                <li className="active"><Link to="/">Main</Link></li>
                <li><Link to="studio">Our Studio</Link></li>
                <li><Link to="project">Projects</Link></li>
                <li><Link to="contact">Contact</Link></li>
              </ul>
            </div>
          </div>
        </div>
    );
}

export default Navigation;
  