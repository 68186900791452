import { default as BlogSection } from '../components/BlogSection';

const Blog = () => {
  return (
      <div>
        <BlogSection/>
      </div>
  );
}
  
export default Blog;