import './PortfolioSection.css';

import { Link } from "react-router-dom";

const PortfolioSection = () => {
    return (
        <section id="portfolio">
            <div className="container">
                  <div className="row">

                      <div className="col-md-4 col-sm-6">
                            <a target="_blank" href="https://www.veryberrybap.com/">
                                <div className="portfolio-thumb">
                                      <img src="images/project-image1.png" className="img-responsive" alt="Portfolio"/>
                                          <div className="portfolio-overlay">
                                                <div className="portfolio-item">
                                                    <h3>베리베리뱁</h3>
                                                    <small>Brand veryberrybap</small>
                                                </div>
                                          </div>
                                </div>
                            </a>
                      </div>

                      <div className="col-md-4 col-sm-6">
                            <a target="_blank" href="https://tumblbug.com/clichebreaker">
                                <div className="portfolio-thumb">
                                      <img src="images/project-image2.png" className="img-responsive" alt="Portfolio"/>
                                          <div className="portfolio-overlay">
                                                <div className="portfolio-item">
                                                    <h3>이 로판은 제가 접수하겠습니다</h3>
                                                    <small>Brand clichebreaker</small>
                                                </div>
                                          </div>
                                </div>
                            </a>
                      </div>

                      <div className="col-md-4 col-sm-6">
                            <a>
                                <div className="portfolio-thumb">
                                      <img src="images/project-image3.png" className="img-responsive" alt="Portfolio"/>
                                          <div className="portfolio-overlay">
                                                <div className="portfolio-item">
                                                    <h3>한꾸기와 네 꾸기들</h3>
                                                    <small>Brand K-DECK</small>
                                                </div>
                                          </div>
                                </div>
                            </a>
                      </div>

                      <div className="col-md-4 col-sm-6">
                            <a>
                                <div className="portfolio-thumb">
                                      <img src="images/project-image4.png" className="img-responsive" alt="Portfolio"/>
                                          <div className="portfolio-overlay">
                                                <div className="portfolio-item">
                                                    <h3>아치하이브</h3>
                                                    <small>Brand ARCH-HIVE</small>
                                                </div>
                                          </div>
                                </div>
                            </a>
                      </div>

                      <div className="col-md-4 col-sm-6">
                            <a>
                                <div className="portfolio-thumb">
                                      <img src="images/project-image5.png" className="img-responsive" alt="Portfolio"/>
                                          <div className="portfolio-overlay">
                                                <div className="portfolio-item">
                                                    <h3>이고 플레이어</h3>
                                                    <small>Brand EGOPLAYER</small>
                                                </div>
                                          </div>
                                </div>
                            </a>
                      </div>

                      <div className="col-md-4 col-sm-6">
                            <a>
                                <div className="portfolio-thumb">
                                      <img src="images/project-image6.png" className="img-responsive" alt="Portfolio"/>
                                          <div className="portfolio-overlay">
                                                <div className="portfolio-item">
                                                    <h3>NEXT SOON</h3>
                                                    <small>SUB Brand</small>
                                                </div>
                                          </div>
                                </div>
                            </a>
                      </div>

                      <div className="col-md-12 col-sm-12 text-center">
                            <h3>hello, if you interest working together. just send message <Link to="contact">contact page</Link></h3>
                      </div>

                  </div>
            </div>
        </section>
    );
}

export default PortfolioSection;