import './App.css';
import { Routes, Route } from 'react-router-dom';

import { default as Preloader } from './components/Preloader';
import { default as Navigation } from './components/Navigation';
import { default as Footer } from './components/Footer';
import { default as Index } from './pages/Index';
import { default as Studio } from './pages/Studio';
import { default as Blog } from './pages/Blog';
import { default as Contact } from './pages/Contact';

function App() {
  return (
    <div>
      <Preloader/>
      <Navigation/>
      <Routes>
        <Route path="/" element={<Index/>}/>
        <Route path="/studio" element={<Studio/>}/>
        <Route path="/project" element={<Blog/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
